html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Mulish", sans-serif;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: 500;
  color: #17664e !important;
}

.MuiButton-outlinedPrimary {
  color: #17664e;
  /* border: 2px solid rgba(23, 102, 78, 0.5); */
  border: 1px solid #17664e !important;
  box-sizing: border-box !important;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 9px 4px !important;
}
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  /* padding: 4px; */
  padding-left: 22px !important;
}
.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px);
  /* margin: 39px !important; */
}
/* .MuiGrid-container {
  flex-wrap: nowrap !important;
} */
.App {
  max-width: 1600px;
  margin: 0 auto;
}
