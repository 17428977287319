@media (min-width: 600px)
{
.MuiToolbar-gutters {
    padding-left: 8.6% !important;
}
}

.typo{
    margin-top: 25px;
    margin-left: 2px;
}
